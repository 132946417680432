@font-face {
    font-family: "Roboto-Bold";
    src :url('../fonts/Roboto-Bold.ttf');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "Roboto-Regular";
    src :url('../fonts/Roboto-Regular.ttf');
    font-weight: normal;
    font-style: normal;
}

@keyframes scaleUpDown {
    from {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
    to {
        opacity: 1;
        -webkit-transform: scale(1.1);
        transform: scale(1.1);
    }
}

.ffrb{
    font-family: "Roboto-Bold";
}
.ffrg{
    font-family: "Roboto-Regular";
}
.swiper{
    position:relative;
    &:after{
        content:"";
        display:block;
        position:absolute;
        left:0;
        top:0;
        width:100%;
        height:100%;
        background:rgba(0,0,0,0.5);
        z-index:10;
    }
}
.home{
     background-size: cover;
     position:absolute;
     width:100%;
     height: 100%;
     overflow: hidden;
    .download{
        display:flex;
        align-items:center;
        margin-top:.64rem;
        justify-content: center;
        .download-item{
            width: 3.3rem;
            margin:0 .1rem;
            cursor: pointer;
            img{
                max-width:100%;
            }
        }
    }
    .swiper-container{
        position:absolute;
        width:100%;
        height: 100%;
        .img{
            width: 100%;
            height:100%;
            transition-origin:center center;
            object-fit: cover;
            transform:scale(1.1) translate(10px, 0);
            transition:transform 7.6s linear;
        }
        .swiper-slide-active{
            .img{
                transform:scale(1) translate(0px, 0);
                transition-delay:.2s;
                //animation: scaleUpDown 10.6s .3s forwards ease-out;
            }
        }
    }
    .main{
        height: 100%;
        position:absolute;
        left:0;
        top:0;
        z-index:1000;
        display:flex;
        align-items: center;
        width:100%;
        justify-content: center;
    }
}
.header-top{
    z-index:1000;
}
.footer{
    z-index:1000;
}
.link{
    color:#08c;
    cursor: pointer;
}
.copyright{
    @media(max-width: 750px){
        margin-top:15px;
    }
}
.line{
    position:absolute;
    bottom:7%;
    left:0;
    text-align: center;
    width:100%;

    span{
        width: 0.3rem;
        cursor: pointer;
        display: inline-block;
        vertical-align: middle;
        height: 0.06rem;
        background: #E8E8E8;
        position:relative;
        z-index:100;
        border-radius: 3px;
        margin:0 0.15rem;
        &.line-cur{
            background:var(--primary-color);
        }
    }
}


.wrapper{
    height:0.6rem;
    position:relative;
    display:flex;
    align-items:center;
    justify-content: center;
    font-size: 0.24rem;
    color:#212121;
    font-weight: 500;
    .logo img{
        width:0.6rem;
        position:absolute;
        left:0;
        top:0;
    }
}
.f-line{
    width:100%;
    height:1px;
    background:#E6E9F0;
    margin-top:0.4rem;
    margin-bottom: 5px;
}

.page-home{
    //background:url('../images/bg.png') no-repeat center center;
    background-size: cover;
}

.modal-title{
    span{
        font-size: 14px;
        color:#999;
        margin-left:10px;
    }
}

textarea{
    resize:none !important;
}

.ant-form-item-label > label{
    min-height:32px !important;
    height:auto !important;
}

